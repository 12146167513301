<!--
 * @Author: 张阳帅
 * @Date: 2024-09-23 17:52:37
 * @LastEditTime: 2021-03-12 16:46:14
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \BlueCrossInternetHospitalGZH\src\views\appoint\deptList.vue
-->
<template>
  <div class="appoint">
    <van-tree-select height="93vh" :items="deptList" :main-active-index.sync="select_active" class="office" @click-item="onNavClick" />
  </div>
</template>
<script>
import {gjcModel} from '../../../api/gjcApi'

export default {
    data(){
        return{
            deptList:[], //科室列表
            select_active: 0
        }
    },
    mounted() {
        this.getDepartDescData()
    },
    methods:{
        //获取科室列表
        getDepartDescData(){
            gjcModel.getDepartDescData().then((result) => {
                result.data.forEach((item)=> {
                    const selectDeps = {}
                    selectDeps.text = item.ksflmc
                    selectDeps.id = parseInt(item.ksfl)
                    const childArray = []
                    item.departChild.forEach((children)=> {
                        const smallDeps = {}
                        smallDeps.text = children.ksmc
                        smallDeps.id = parseInt(children.id)
                        childArray.push(smallDeps)
                    })
                    selectDeps.children = childArray
                    this.deptList.push(selectDeps)
                })
            })
        },
        onNavClick(item){
            console.log('选择科室', item)
            this.$router.push({
                path: '/deptDetail',
                query: {
                    id:String(item.id)
                }
            })
        }
    }
}
</script>
<style scoped lang='less'>

</style>
